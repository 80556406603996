@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');
/* colors */
:root{
    --Deep-green: #164B2E;
    --Light-green: #2D995F; 
    --Gray:#f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}
body{
    margin: 0;
    padding: 0;
}
.imgSpace{
    /* border: 1px solid pink; */
    width: 45%;
}
.LoginImg{
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}
body{
    /* background-image: url("../../assets/loginBg.png"); */
    background-size: cover;
    background-repeat: no-repeat;
}
.inputField{
    width: 40%;
    /* border: 1px solid green; */
    text-align: center;
    /* margin-top: 1%; */
    padding: 2% 0% 0% 4%;
}
.inputField h4{
    font-size: 20px;
    color: #343A40;
    font-weight: 700;
    /* margin-top: 10px; */
    margin: 0;
    margin-bottom: 10px;

}
.inputField input{
    outline: none;
    border-color: initial!important;
    border: 1px solid #ADB5BD!important;
    color: #6C757D!important;
}
.inputField .logo{
    width: 225px;
    height:  160px;
}
.inputField h2{
    color: #6C757D;
    font-size: 28px;
    margin: 0;
    font-weight: 700;
    margin-top: 3%;
    margin-bottom: 3%;
}
.general{
    width: 100%;  
    /* justify-content: space-between;   */
    gap: 5%;
    background-image: url('../../Assets/loginBg.png');
    display: flex;
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.loginBtn{
    margin-left: 0px;
}


.noScale:hover {
    transform: none; /* No scaling on hover */
}

.newStdInput{
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ADB5BD;
    width: 410px;
    width: 410px;
    margin-bottom: 20px;

}
.loginBtn{
    margin-top: 3%;
    background-color: var(--Light-green);
    border: none;
    border-radius: 4px;
    color: var(--White);
    padding: 2.5% 36% 2.5% 37% !important;
}
.header {
    /* padding-top: 36px; */
    text-align: center; /* Center align the text */
}

.headerTxt {
    font-size: 28px;
    color: var(--blackGray);
    white-space: nowrap;
    font-weight: 700;
    font-family: inter;
    color: #6C757D;
    margin-top: 12px; /* Add margin to separate image and text */
}

.Inpcontaer {
    border: 1px solid #2D995F;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5% 0% 6% 0%;
}

.logo {
    width: 225px;
    height: 167px;
    display: block;
    margin: 0 auto;
}
.inputContainer{
    margin-bottom: 10px;
    text-align: left;
    /* margin: auto; */
    margin-left: 10%;
}
.inputContainer label{
    font-size: 12px;
    font-weight: 400;
    /* text-align: left; */
    color: #343A40;

}
.inputContainer input{
    width: 80%;
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    padding: 14px 30px 14px 10px;
    /* pointer-events: none; */
}
