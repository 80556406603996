@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

.buttonToggle {
  display: flex;
  padding: 0.5% 1% 0.5% 1%;
  gap: 2%;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  width: 28.2%;
  margin-top: -0.8%;
  background-color: #E9ECEF;
  height: 30px;
}

.toggleButton {
  width: 100%;
  margin-left: 0px;
  padding: 10px 20px;
  background-color: #E9ECEF;
  color: #343A40;
  border: none;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  line-height: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.toggleButton.selected {
  background-color: #ffffff;
  width: 100%;
  color: #2D995F;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.toggleButton:hover {
  background-color: #d4d4d4; /* Change this to your desired hover background color */
  color: #000000; /* Change this to your desired hover text color */
}

.head {
  font-size: 16px;
  color: #343A40;
  font-weight: 500;
  font-family: inter;
}
