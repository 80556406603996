.appcontainer {
    display: flex;
    height: 100vh;
}


  
  .sidenav {
    flex: 0 0 300px; /*Fixed width for the sidebar*/
    background-color: #f0f0f0;
    overflow-y: auto; /*Enable vertical scrolling*/
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; 
  } 
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh;
    
  }
  
  .scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
  }

  .pysf {
    width: 296.3px;
    height: 152.78px;
    margin-bottom: 18px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease
  }

  img:hover {
    transform: scale(1.1); /* Scale the image to 110% on hover */
}

  .rgstrurcurses {
    width: 296.3px;
    height: 152.78px;
  }

  .fundurwlt {
    width: 140.74px;
    height: 219.44px;
  }

  .chekurrslt {
    width: 140.74px;
    height: 219.44px;
  }
  .gturcrsmtrls {
    width: 140.74px;
    height: 219.44px;
  }
  
  .qlnks {
    display: flex;
    /* justify-content: space-around; */
    align-items: left;
    margin-left: 25px;
    gap: 18px;
  }

  .qlnks1 {
    display: flex;
    /* justify-content: space-between; */
    align-items: right;
    /* margin-left: 25px; */
    gap: 530px;
    background-color: none;
    margin-bottom: -15px;
    margin-top: 10px;
  }

  .dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 10px;
  }

  .frstcolumn {
    /* display: flex; */
    /* flex-direction: column;
    justify-content: space-between; */
    width: 261px;
    height: 100px;
    overflow: hidden;
    
    background-color: white;
    padding: 15px;
    border-radius: 8px;

    

    h2 {
      font-size: 23.58px;
      font-weight: 700;
      padding-left: 10px;
      margin: 0; 
      padding-top: 10px;    
    }
    p {
      font-size: 9px;
      font-weight: 400;
      padding-left: 10px;     
      margin: 0;     
    }
  }

  .dshlstb1 {
    /* display: flex; */
    /* flex-direction: column;
    justify-content: space-between; */
    width: 340px;
    height: 290px;
    overflow: hidden;
    background-color: none;
    /* padding: 15px; */
    /* border-left: 0.7rem solid #2D995F; */
    /* border-radius: 8px; */
    border-radius: 4px;

    h2 {
      font-size: 18px;
      font-weight: 700;
      padding-left: 10px;
      margin: 0;     
    }
    p {
      font-size: 10px;
      font-weight: 300;
      padding-left: 10px;     
      margin: 0;     
    }
  }

  .dshlstb {
    /* display: flex; */
    /* flex-direction: column;
    justify-content: space-between; */
    width: 267px;
    height: 105px;
    overflow: hidden;
    background-color: white;
    padding: 25px 10px 10px 10px;
    border-left: 0.5rem solid #2D995F;
    border-radius: 8px;

    h2 {
      font-size: 11.79px;
      font-weight: 700;
      padding-left: 5px;
      margin: 0;     
    }
    p {
      font-size: 6.55px;
      font-weight: 300;
      padding-left: 5px;     
      margin: 0;     
    }
  }

  .frstcolumn1 {
    background: url('../Assets/bck1.jpg');
    /* display: flex; */
    /* flex-direction: column;
    justify-content: space-between; */
    width: 520px;
    height: 241px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    /* background-size: contain; */
    padding: 15px;
    border-radius: 5.24px;
    h2 {
      font-size: 42px;
      font-weight: 900;
      padding-left: 57px;
      padding-top: 37px;
      margin: 0;    
      color: white; 
    }
    p {
      font-size: 13.1px;
      font-weight: 400;
      padding-left: 57px;     
      margin: 0;     
      color: white; 
    }

    button {
      margin-left: 57px;
      margin-top: 25px;
      width: 145px;
      height: 37px;
      background-color: white;
      color: #2D995F;
      font-size: 13.1px;
      font-weight: 700;
      
    }
  }

  .frstsubcolumn {
    display: flex;
    justify-content: space-between;
  }

  .intxt {
    display: flex;
    padding-left: 57px;
    margin-top: 30px;
    /* justify-content: space-between; */
  }

  .intxtt {
    display: flex;
    padding-left: 57px;
    margin-top: 0;
    /* justify-content: space-between; */
  }

  .intxtt1 {
    display: flex;
    padding-left: 4px;
    margin-top: 15px;
    /* justify-content: space-between; */
  }

  .intxtt12 {
    display: flex;
    padding-left: 3px;
    margin-top: 10px;
    /* justify-content: space-between; */
  }

  .lttext {
    display: flex;
    /* padding-left: 57px; */
    /* margin-top: 5px; */
    /* justify-content: space-between; */
    gap: 30px;
  }

  .intxt1 {
    font-size: 120px;
    font-weight: 400;
    color: #6C757D;
    margin-top: 0;
    p {
      font-size: 6.55px;
      font-weight: 400;
    }
  }

  .intxt2 {
    /* font-size: 120px; */
    font-weight: 400;
    color: #6C757D;
    margin-top: 0;
    p {
      font-size: 10.48px;
      font-weight: 400;
      padding-left: 5px; 
    }
  }

  .clk1 {
    width: 9.1px;
    height: 10.48px;  
  }

  .clk2 {
    width: 9.1px;
    height: 10.48px;  
  }

  .date {
    width: 9.11px;
    height: 10.48px;
  }

  .qlnktxt {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D;
    /* border-bottom: 1px solid #CED4DA; */
    /* padding-left: 25px;
    padding-bottom: 15px; */
    margin-top: 30px;
  }

  .qlnktxt1 {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D;
    /* padding-left: 25px; */
    margin-top: 35px;
  }

  .evntkad {
    background-color: white;
    border-left: 8px solid #2D995F;
    height: 136.27px;
    width: 309.36px;
    border-radius: 6px;
    padding: 1px 5px 10px 20px;
  }
  .evntkad2 {
    background-color: white;
    border-left: 8px solid #2D995F;
    height: 136.27px;
    width: 309.36px;
    border-radius: 6px;
    padding: 1px 5px 10px 20px;
    margin-top: 20px;
  }

  .evntlrgtxt {
    font-size: 15px;
    font-weight: 700;
    color: #343A40;
    /* padding-top: 15px; */
  }


  .evntkadcntnt {
    margin-bottom: 15px;
  }
  .evntlrgtxtlil {
    font-size: 8px;
    font-weight: 300;
    color: #6C757D;
    margin-top: -10px;
    width: 85% ;
    font-family: 'inter';
  }

  .dtetxt {
    font-size: 10px;
    font-weight: 300;
    font-family: 'inter';
  }

  .calendarimg {
    width: 9.93px;
    height: 11.41px ;
  }

  .clockimg {
    width: 17.12px;
    height: 17.12px;
  }
  .locationimg {
    width: 14px;
    height: 20px ;
  }

  .clndrcntnt {
    display: flex;
    align-items: center;
    gap: 3%;
    width: 100%;
  }
  .clndrcntnthead {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: -8px;
   
  }


  .icon333 {
    width: 44px;
    height: 44px;
  }

  .icon222 {
    width: 44px;
    height: 44px;
  }

  .icon111 {
    width: 44px;
    height: 44px;
  }

  .head1 {
    width: 1100px;
    height: 440px;
  }

