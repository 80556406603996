@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    width: 88%;
    height: auto;
    /* background-color: pink; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

.container {
    padding: 20px;
  }
  .subContainer{
    display:flex;
    align-items: center;
    text-align: center;
    gap:10px;
    margin-left: 22px;
}

.subContainer img{
    width: 5px;
    height:11px;
}

.subContainer p{
    font-size: 14px;
    font-weight: 400;
    color:#6C757D;
}
  .assignments {
    margin-bottom: 20px;
    display:flex;
    justify-content: space-between;
    gap:10px;
  }
  
  .newassign{
    color: #6C757D; /* Matching the green color for headings */
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top:0px;
  }
  
  .assignmentcard {
    display: flex;
    align-items: center;
    border: 1px solid #2D995F; /* Matching the border color */
    border-radius: 4px;
    padding: 15px;
    padding-left: 0px;
    margin-bottom: 10px;

    button {
      background-color: #2D995F;
      color: white;
      font-size: 9px;
      font-weight: 400;
      width: 155px;
      height: 45px;
      border-radius: 4px;
      border: none;

      .viewsub {
          text-decoration: none;
          color: white;
      }
    }
  }
  
  .assignmentcard .icon {
    width: 80px;
    height: 80px;
    margin-right: 0px;
  }
  
  .assignmentinfo1 {
    font-size: 15px;
    font-weight:700;
    color: #343A40; /* Darker color for text */
    /* margin-bottom: 4px; */
    /* margin-top: 0; */
    margin: 0;
  }
  
  .assignmentinfo p {
    font-size: 8px;
    font-weight: 400;
    color: #6C757D; /* Grey color for description text */
    margin-bottom: 5px;
    margin-top: 15px;
  }
  
  .assignmentinfo a {
    font-size: 8px;
    font-weight:400;
    color: #2D995F; /* Matching green color for links */
    text-decoration: none;
    margin-bottom: 0px;
  }
  
  
  .assignmentinfo .date {
    font-size: 10px;
    font-weight: 300;
    color: #6C757D; /* Grey color for date */
    /* margin-left: 15px; */
    margin-right: 0;
    margin-top: 0;
  }
  
  .olderassign{
    color: #6C757D; /* Matching the green color for headings */
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
  }
 

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    /* margin-top: 44px; */
    /* margin-left:43px; */
    margin-bottom:11px
}

.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
  flex: 0 0 300px; /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
}

.maincontent {
  margin-left: 300px; /* Same width as the sidebar to prevent overlap */
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: auto; /* Ensure main content can scroll if needed */
  height: 100vh; /* Full height of the viewport */
}

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
