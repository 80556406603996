@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}
.createClassPo{
    position: absolute;
    right: 0;
    margin-right: 2%;
    top: 3.4%;
}
.header{
    display: flex;
    width: 100%;

}
.moving {
    padding-left: 400px;
    padding-top: 100px;
}
.month{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 50px;
    margin-top: -1.4%;
}
.month img{
    width: 27px;
    height: 27px;
    margin: 0 5px;
}
.createClass{
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: #2D995F;
    font-weight: 400;
}
.createClass img{
    width: 24px;
    height: 24px;
    margin-top: -4px;
}
.txt {
    font-size: 18px;
    font-weight: 700;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 1.4%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    width: 90%;
    position: relative;
    background-color: var(--White);
    margin-left: 2.1%;
}

.innerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.pysf {
    width: 296.3px;
    height: 152.78px;
    margin-bottom: 18px;
}

img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease
}

img:hover {
    transform: scale(1.1);
    /* Scale the image to 110% on hover */
}

.rgstrurcurses {
    width: 296.3px;
    height: 152.78px;
}

.hostlfees {
    width: 140.74px;
    height: 219.44px;
}

.fundurwlt {
    width: 140.74px;
    height: 219.44px;
}

.chekurrslt {
    width: 140.74px;
    height: 219.44px;
}

.gturcrsmtrls {
    width: 140.74px;
    height: 219.44px;
}

.qlnks {
    display: flex;
    /* justify-content: space-around; */
    align-items: left;
    margin-left: 25px;
    gap: 18px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 10px;
    margin-top: 20px;
}

.frstcolumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: auto;
    overflow: hidden;
}

.frstsubcolumn {
    display: flex;
    justify-content: space-between;
}

.examTbContainer {
    width: 100%;
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    margin-top: 3%;
}

.examTable {
    position: relative;
    width: 100%;
    border: 1px solid #ADB5BD;
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensures the border-radius is applied properly */
    table-layout: fixed;
    border-collapse: collapse; /* Collapse borders */
}

.examTable th {
    text-align: center;
}

.examTable .calendarCaption p {
    margin: 0;
    /* padding: 0.5% 0 0.5% 1%; */
    text-align: left;
    font-size: 16px;
    color: #2D995F;
}

.examTable .calendarCaption span {
    font-size: 12px;
    color: #6C757D;
}

.calendarCaption {
    text-align: left;
    font-size: 16px;
    border-bottom: 1px solid #ADB5BD;
    padding: 1% 0% 0% 1%;
}

.examTable td {
    border: 1px solid #ADB5BD; /* Border for each cell */
    border-collapse: collapse;
    /* padding: 10px; */
    padding: 0;
}

.examTable th {
    background-color: #ffffff;
    color: #6C757D;
    font-size: 12px;
    font-weight: 400;
    padding: 1% 1% 1% 1%;
}

.examTable tbody td {
    text-align: left;
    vertical-align: top;
    border: 1px solid #ADB5BD; /* Border for each cell */
}
.eventDayy{
    display: flex;
    gap: 8px;
    margin: 0;
    border-radius: 4px;
    color: #86591D;
    padding: 0% 10%;
    background-color: #F8ECDD;
}
.eventDayg{
    display: flex;
    gap: 8px;
    margin: 0;
    border-radius: 4px;
    color: #227247;
    padding: 0% 10%;
    background-color: #ACE7C7;
}
.verline{
   width: 3px;
   /* height: 100px;  */
   border-radius: 8px;
   background-color:#2D995F;
}
.txtVer .header1{
    /* color: #227247; */
    font-size: 38px;
    font-weight: 800;
    margin-top: 0;
}
.txtVer .course{
    /* color: #6C757D; */
    font-size: 14px;
    font-weight: 400;
    margin-top: -30%;
}
.txtVer .fooTxt1{
    /* color: #2D995F; */
    font-size: 10px;
    font-weight: 300;
    margin-top: -10%;
}
/* .examTable td:nth-child(even){
    background-color: #ffffff;
} */
.examTable tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.txtVer .fooTxt{
    /* color: #2D995F; */
    font-size: 10px;
    font-weight: 300;
    margin-top: -5%;
}
