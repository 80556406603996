@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
  --Deep-green: #164B2E;
  --Light-green: #2D995F;
  --Gray: #f2f3f3;
  --White: #ffffff;
  --Black: #343A40;
  --blackGray: var(--Light-green);
  border: var(--Light-green);
}

body {
  background-image: none;
  font-family: inter;
}

.moving {
  padding-left: 400px;
  padding-right: 20px;
  padding-top: 100px;
}

.txt {
  font-size: 14px;
  color: #6C757D;
}

.dashBoardDiv {
  padding-top: 0.5%;
  padding-bottom: 12px;
  width: 88%;
  height: auto;
  /* background-color: pink; */
  justify-content: center;
  background-color: var(--White);
  margin-left: 3%;
}

.toptable{
display:flex;
justify-content: space-between;
align-items: center;
text-align: center;
/* font-size: 30px;
font-weight: 500; */
}

.topDiv{
display:flex;
align-items: center;
gap: 10px;
margin-left: 43px;
/* font-size: 30px;
font-weight: 500; */
}

.topDiv img{
width:5px;
height:11px
}

.topDiv p{
font-size: 16px;
font-weight: 500;
color:#343A40;
}

.container {
width: 90%;
margin: auto;
background-color: white;
padding: 20px;
}

/* .container h2 {
margin-bottom: 10px;
} */

.container p {
margin-bottom: 20px;
font-size: 14px;
font-weight: 400;
color:#6C757D;
}

.container table {
width: 100%;
border-collapse: collapse;
margin-bottom: 20px;
}

.tScore .sScore{
background-color: #6C757D;
color: white;
}

.thScore .sScore {
padding: 10px;
/* text-align: left; */
font-size: 14px;
font-weight: 400;
color: #ffffff;

}


.tbScore .hScore:nth-child(even) {
background-color: #E9ECEF ;
}

.tbScore .hScore:nth-child(odd) {
background-color: #ffffff;
}
/* .tbScore .bScore{
padding: 10px;
border-bottom: 1px solid #ddd;
font-size: 12px;
font-weight: 400;
color:#6C757D;
background-color: #ffffff;
} */

input[type="text"] {
width: 50px;
text-align: center;
border:0.5px solid #2D995F;
border-radius: 4px;
padding:6px 12px 6px 12px;
font-size: 12px;
font-weight: 400;
color:#2D995F;

}

.container .uploadbtn {
width:auto;
background-color: #2D995F;
color: white;
padding: 10px 60px 10px 60px;
border: none;
border-radius: 4px;
cursor: pointer;
margin-bottom: 0;
height: 38px;
}

.uploadbtn:hover {
background-color: #45a049;
}

.dshbrdtxt {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #CED4DA;
  padding-left: 25px;
  padding-bottom: 15px;
  /* margin-top: 44px; */
  /* margin-left:43px; */
  margin-bottom:11px
}

.appcontainer {
  display: flex;
  height: 100vh;
}



.sidenav {
  flex: 0 0 300px; /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
}

.maincontent {
  margin-left: 300px; /* Same width as the sidebar to prevent overlap */
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: auto; /* Ensure main content can scroll if needed */
  height: 100vh; /* Full height of the viewport */
}

.scrollable {
  padding: 30px;
  /* Additional styles for the scrollable content inside the sidebar */
}
