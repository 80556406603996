@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    margin-top: 3%;
    padding-bottom: 12px;
    width: 94%;
    height: auto;
    /* background-color: pink; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

  .subContainer{
    display:flex;
    align-items: center;
    text-align: center;
    gap:10px;
    margin-left: 22px;
    margin-bottom: 20px;
}

.topIcon{
    width: 5px;
    height:11px;
}

.assignment{
    font-size: 14px;
    font-weight: 400;
    color:#6C757D;
}
.assignmentcontainer {
  display:flex;
  width:95%;
  /* margin: 0 auto; */
  margin-top: 0;
  padding: 20px;
  /* background-color: #f7f7f7; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.Topgroup{
  width:60%;
}

.assignmentcard {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 15px;
  padding-left: 0px;
  margin-bottom: 10px;
  padding-top: 0;
}

.assignmentcard .icon {
  width: 54px;
  height: 54px;
  margin-right: 0px;
  margin-left: 10px;
}



.assignmentinfo .date {
  font-size: 8px;
  font-weight: 300;
  color: #6C757D; /* Grey color for date */
  margin-left: 10px;
  margin-right: 0;
  margin-top: 0;
}

.bassign{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    width:97%;
}

.Topprev{
  width:40%;
  margin-top: 0;
}

.prevAssign{
  font-size: 14px;
  font-weight: 400;
  color:#343A40;
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 0;
}

.midrev{
  width:100%;
  border:1px solid #ADB5BD;
  border-radius: 8px;
  margin-top: 0;
}
/* .dashBoardDiv h4{
  color:#343A40;
  font-size:16px;
  font-weight:400;
  margin-left: 20px;
} */

.formgroup {
  margin-bottom: 20px;
  width:100%;
}

.title{
  font-size: 16px;
  color:#343A40;
  font-weight: 400;
  margin-bottom: 8px;
}

.formLabel {
  color:#343A40;
  font-size: 16px;
  font-weight: 400;
}

.formgroup .forminput {
  width: 88%;
  padding: 20px;
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  outline:none;
  box-sizing: border-box;
  margin-top: 8px;
  text-align: start;
  color:#343A40;
}
.midgroup{
    margin-bottom: 20px;
    width:100%;
}

.midgroup label{
    color:#343A40;
    font-size: 16px;
    font-weight: 400;
}

.date{
    display: flex;
    justify-content: space-between;
    width:70%;
}
.sDate{
    margin-right: 32px;
}

.dateText{
    font-size: 14px;
    font-weight: 400;
    color:#343A40;
}
.sDate .dBtn{
    display: flex;
    gap:60px;
    width:auto;
    padding:10px 20px;
    border:1px solid #ADB5BD;
    border-radius: 4px;
    outline:0;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color:#6C757D;
    margin-bottom: 40px;
    margin-left: 0;
    align-items: center;
}

.details{
    border:1px solid #ADB5BD;
    border-radius: 4px;
    margin-top: 8px;
    outline:none;
    width:86%
}

.uploadarea {
  width:80%;
  border: 1px solid #ADB5BD;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  display:flex;
  flex-direction: column;
  margin-top: 10px;
  color:#2D995F;
  font-size: 14px;
  font-weight: 400;
}

.uploadiconimg{
  /* margin-right: 10px; */
  width:89px;
  height:89px;
}

.uploadtext{
  font-size: 16px;
  font-weight: 400;
  color:#343A40;
}

.uploadinput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.submitbutton {
  background-color: #2D995F;
  color: white;
  padding: 10px 69px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0;
  width:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.previousassignments {
  margin-top: 30px;
  width:auto;
}

.assignheader{
  color:#6C757D;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 10px;

}

.assignmentlist {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}

/* .assignmentlist li {
  background-color: #e0e0e0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
} */
.assignmentinfo{
    border-left:1px solid #2D995F;
    border-width: 4px;
    border-radius: 1px;
    margin-left: 8px;
}

.assignheader{
  font-size:14px;
  font-weight:400;
  color:#6C757D;
  margin-top: 0;
  margin-bottom:4px;

}

.assignp{
  font-size:8px;
  font-weight:300;
  color:#6C757D;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.viewassign{
    font-size: 8px;
    color:#2D995F;
    font-weight: 300;
}

/* .assignmentlist li::before {
  content: '📖';
  margin-right: 10px;
} */

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    /* margin-top: 44px; */
    /* margin-left:43px; */
    margin-bottom:11px
}

.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
  flex: 0 0 300px; /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
}

.maincontent {
  margin-left: 300px; /* Same width as the sidebar to prevent overlap */
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: auto; /* Ensure main content can scroll if needed */
  height: 100vh; /* Full height of the viewport */
}

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
