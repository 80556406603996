@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding: 0% 0% 18% 0%;
    width: 94%;
    /* background-color: pink; */
    /* padding-left: 40px; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
    align-items: center;
}
.topContnt{
    width: 100%;
    padding: 0% 0% 2% 2.2%;
    margin: 0;
}


.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 7px;
    margin-top: 20px;
}

.appcontainer {
    display: flex;
    height: 100vh;
}

.dreg {
    width: 100%;
}

.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.icons {
    display: flex;
    gap: 15px;
}

.subContainer img{
    width: 5px;
    height:11px;
}
.subContainer{
    display:flex;
    align-items: center;
    gap:10px;
    margin: 0;
    margin-left: 10px;
}
.subContainer p{
    color: #343A40;
    font-weight: 500;
    font-size: 14px;
}

.heas {
    color: #343A40;
    font-size: 16px;
    font-weight: 700;
}

.underSelection {
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: space-between;
    /* border: 1px solid pink; */
    align-items: center; /* Center items vertically if needed */
}
.date{
    padding: 0;
    width: auto;
}
.courses, .date {
    padding: 12px 20px;
    width: calc(100% + 0px); /* Adjusting for padding, margin, or border */
    color: #6C757D;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #ADB5BD;
    height: auto;
}

.labelFr {
    font-size: 14px;
    color: #343A40;
    font-weight: 400;
}

.createClass{
    padding: 1% 3% 1% 3%;
    width: 287px;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    background-color: #2D995F;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    margin-left: 0;
    margin-top: 4.5%;
    /* line-height: 10px; */
    font-weight: 500;
    height: auto;
    margin-bottom: 0;
}