@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    /* padding: 2.5% 2% 2% 2%; */
    width: 93%;
    height: auto;
    /* background-color: pink; */
    /* padding-left: 40px; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
    align-items: center;
}
.topContnt{
    width: 100%;
    padding: 0% 2% 2% 2%;
    margin: 0;
}

.headerText h4 {
    font-size: 16px;
    font-weight: 600;
    color: #6C757D;
    margin-bottom: 0px;
    padding-bottom: 12px;
}

.headerText p {
    font-size: 12px;
    color: #6C757D;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 12px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 7px;
    margin-top: 20px;
}



.appcontainer {
    display: flex;
    height: 100vh;
}

.dreg {
    width: 100%;
}

.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.inputTable {
    width: 100%;
    border: none;
    border-radius: 4px;
    margin-top: 0;
    table-layout: fixed;
    border-spacing: 0;
}

.abcs {
    border: 1px solid #CED4DA;
    border-radius: 4px;
    margin-top: 0;
    padding-top: 0;
    width: 96%;
}

.captionfor {
    text-align: left;
    font-weight: 400;
    color: #6C757D;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 5px;
}

.inputTable thead {
    background-color: #ffffff;
}

.inputTable thead th {
    background-color: #ffffff;
    padding-top: 0.7%;
    padding-bottom: 0.4%;
    margin: 0;
    border-bottom: 1px solid #CED4DA;
    width: 80%;
    vertical-align: middle;
}

.inputTable th[colspan="1"] {
    width: 5%;
}

.inputTable tbody td {
    padding: 0;
    height: calc(80vh - 30vh - 20px);
    /* Remaining height */
}

.strLineHeader {
    border: 1px solid #ADB5BD;
    width: 0px;
    margin-left: 10px;
}
.inputTable thead tr{
    padding: 2% 2% 2% 2%;
}
.inputTable select {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D;
    border: none;
}

.icons {
    display: flex;
    gap: 15px;
}

.inputField {
    width: 100%!important;
    height: 100%!important;
    border: none!important;
    box-sizing: border-box!important;
    padding: 10px!important;
    font-size: 14px!important;
    margin: 0!important;
    outline: none!important;
    display: block!important;
    text-align: left!important;
    /* vertical-align: top; */
    overflow: hidden!important;
    color: #343A40!important;
}

.inputField::placeholder {
    text-align: left;
    vertical-align: top;
    line-height: 1.2;
    position: absolute;
    top: 0;
}
.forminput::placeholder {
    text-align: left!important;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
}
.forminput{
    color: #343A40!important;
    font-size: 24px!important;
    font-weight: 700!important;
}
.boldIcon {
    width: 37px;
    height: 37px;
    cursor: pointer;
}
.subContainer img{
    width: 5px;
    height:11px;
}
.subContainer{
    display:flex;
    align-items: center;
    gap:10px;
    margin: 0;
    margin-left: 10px;
}
.subContainer p{
    color: #343A40;
    font-weight: 500;
    font-size: 14px;
}
.formgroup label {
    color:#6C757D;
    font-size: 14px;
    font-weight: 400;
  }
  
.forminput {
    width: 96%!important;
    padding: 20px!important;
    border: 1px solid #ADB5BD!important;
    border-radius: 4px!important;
    box-sizing: border-box!important;
    margin-top: 8px!important;
    outline: none!important;
    text-align: left!important;
}
.underButton{
    /* gap: 5%; */
    /* justify-content: space-between; */
    margin-top: 12px;
}
.underButton .pulish{
    margin: 0!important;
    padding: 15px 60px 15px 60px!important;
    width: auto!important;
    /* white-space: nowrap; */
    cursor: pointer;
    line-height: 5px!important;
    color: #ffffff;
    background-color: #2D995F;
    height: auto;
    font-size: 500;

}
.cancelBtn{
    margin: 0;
    background-color: #ffffff;
    color: #343A40;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}