@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    margin-top: 2.5%;
    padding-bottom: 12px;
    width: 95%;
    height: auto;
    /* background-color: pink; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}
.subTop{
  display:flex;
  gap:20px;
  align-items: center;
}

.SelectDsign{
  position:relative;
  width:200px;
  display: inline-block;
}

.SelectDsign::after{
  content: url('../../Assets/icons8-expand-arrow-24.png');
  position: absolute;
  right:10px;
  top:70%;
  transform: translateY(-50%);
  pointer-events: none;
}

.SelectDsign .courseSelect{
  padding-right: 30px;
}

.courseSelect{
  appearance: none;
  width:100%;
  cursor: pointer;
}
.courseLabel{
  font-size: 14px;
  font-weight: 400;
  color:#343A40;
}

.subTop select {
  font-size: 14px;
  font-weight: 400;
  color:#6C757D;
  padding:12px 30px;
  border-radius: 4px;
  border:1px solid #ADB5BD;
  margin-top: 10px;
  outline: none;
}
a{
  text-decoration: none;
}
.testBtn{
  width:250px;
  align-items: center;
  background-color: #2D995F;
  border-radius: 4px;
  padding:10px 20px 10px 20px;
  color:#ffffff;
  border:0;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 0;
  display:flex;
  justify-content: center;
  text-decoration: none;
  
}

/* .toptable{
  display:flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.topDiv{
  display:flex;
  align-items: center;
  gap: 10px;
  margin-left: 43px;
} */

/* .topDiv img{
  width:5px;
  height:11px
}

.topDiv p{
  font-size: 16px;
  font-weight: 500;
  color:#343A40;
} */

.container {
  width: auto;
  margin: auto;
  background-color: white;
  padding: 20px;
  height:100vh;
}

/* .container h2 {
  margin-bottom: 10px;
} */

.topHeader{
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color:#343A40;
}

.container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.container thead {
  background-color: #6C757D;
  color: white;
}

.dshbrdtxt {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #CED4DA;
  padding-left: 25px;
  padding-bottom: 15px;
  /* margin-top: 44px; */
  /* margin-left:43px; */
  margin-bottom:11px
}

txt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 44px;
    margin-left:43px;
    margin-bottom:11px
}

.appcontainer {
    display: flex;
    height: 100vh;
}


.sidenav {
  flex: 0 0 300px; /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
}

.maincontent {
  margin-left: 300px; /* Same width as the sidebar to prevent overlap */
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: auto; /* Ensure main content can scroll if needed */
  height: 100vh; /* Full height of the viewport */
}

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
