@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    /* padding: 2.5% 2% 2% 2%; */
    width: 93%;
    height: auto;
    /* background-color: pink; */
    /* padding-left: 40px; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
    align-items: center;
}
.topContnt{
    width: 100%;
    padding-top: 3%; 
    margin: 0;
}

.headerText h4 {
    font-size: 16px;
    font-weight: 600;
    color: #6C757D;
    margin-bottom: 0px;
    padding-bottom: 12px;
}

.headerText p {
    font-size: 12px;
    color: #6C757D;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 12px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 7px;
    margin-top: 20px;
}



.appcontainer {
    display: flex;
    height: 100vh;
}

.dreg {
    width: 100%;
}

.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.frstcolumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: auto;
    overflow: hidden;
  }

  .frstsubcolumn {
    display: flex;
    justify-content: space-between;
  }

  .qlnktxt {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D;
    padding-left: 25px;
    margin-top: 35px;
  }

  .ellipseimgs {
    width: 77.05px;
    height: 19.98px;
  }

  .crdtxtbg {
    font-size: 25px;
    font-weight: 700;
    color: #343A40;
    margin-bottom: 0px;
  }

  .crdtxtsml {
    font-size: 10px;
    font-weight: 400;
    color: #6C757D;
    margin-top: 1px;
  }

  .crdtxtsml1 {
    font-size: 8px;
    font-weight: 400;
    color: #6C757D;
  }

  .crdownr {
    width: 295px;
    height: 153px;
    background-color: white;
    border: 1px solid #2D995F;
    border-radius: 5px;
  }

  .crdtxt {
    margin-top: -20px;
    margin-bottom: 55px;
    padding-left: 20px;
  }
  .crdtxt1 {
    padding-left: 20px;
  }

  .kadflx {
    display: flex;
    gap: 1.5%;
    margin-left: 25px;
    /* width: 95%; */
  }

  .crdtxtsmlllbg {
    padding-top: 8px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: -5px;
  }

  .stdntsdtlstble {
    background-color: white;
    border: 1px solid #2D995F;
    border-radius: 4px;
    margin-left: 25px;
    padding: 0px 30px 0px 0px;
    /* width: 95%; */
  }

  .stdntsdtls {
font-size: 10px;
color: #343A40;
font-weight: 400;
  }

  .thetr .theth{
    /* display: flex;
    justify-content: space-around;
    text-align: center; */
    /* gap: 10%; */
    text-align: left;
    padding: 20px 0px  0px 30px ;
    
  }

  .stdntsdtls .thetr{
border-bottom: 1.5px solid #E9ECEF ;
  }

  .themjbdy .thethbdy {
    border-bottom: 1.5px solid #E9ECEF;
    padding-bottom: 25px;
    padding-top: 25px;
    text-align: left;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 400;
  }


  .evntkad {
    background-color: white;
    border-left: 8px solid #2D995F;
    height: 136.27px;
    width: 295.36px;
    border-radius: 6px;
    padding: 10px 5px 10px 20px;
  }
  .evntkad2 {
    background-color: white;
    border-left: 8px solid #2D995F;
    height: 136.27px;
    width: 295.36px;
    border-radius: 6px;
    padding: 10px 5px 10px 20px;
    margin-top: 20px;
  }

  .evntlrgtxt {
    font-size: 13px;
    font-weight: 700;
    color: #343A40;
    /* padding-top: 15px; */
  }


  .evntkadcntnt {
    margin-bottom: 30px;
  }
  .evntlrgtxtlil {
    font-size: 7px;
    font-weight: 300;
    color: #6C757D;
    margin-top: -8px;
    width: 85% ;
  }

  .dtetxt {
    font-size: 7px;
    font-weight: 300;
  }

  .calendarimg {
    width: 9.93px;
    height: 11.41px ;
  }

  .clockimg {
    width: 17.12px;
    height: 17.12px;
  }
  .locationimg {
    width: 9.99px;
    height: 14.27px;
  }

  .clndrcntnt {
    display: flex;
    align-items: center;
    gap: 2.5%;
    width: 100%;
  }
  .clndrcntnthead {
    display: flex;
    width: 100%;
    justify-content: space-around;
   
  }

  .dshbrdcntnt {
    display: flex;
    gap: 5%;
  }























  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    /* width: 88%; */
    /* height: 90%; */
    /* background-color: pink; */
    padding-left: 40px;
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

.headerText h4{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-bottom: 20px;
    padding-bottom: 12px;
    display: flex;
    /* justify-content: center; */
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.avt {
    width: 132px;
    height: 132px;
}

.usrinfo {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    border:1px solid #CED4DA;
    border-radius: 4px;
    margin-right: 66px;
}

.userinfoSub{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    padding:24px 0px 24px 25px;
}
.usrnminf {
margin-left: 15px;
}

.usrnme {
    font-weight: bold;
    font-size: 18px;
    margin-bottom:8px;
    /* margin-top: 41px; */
}

.usrpstn {
    font-weight: 500;
    font-size: 16px;
    color:#6C757D;
    margin-top: 0;
}

.uploadBtn button{
    display:flex;
    padding:14px 22px;
    gap: 10px;
    width:160px;
    height:45px;
    border:1px solid #2D995F;
    border-radius: 4px;
    color:#2D995F;
    margin-top: 67px;
    margin-right: 34px;
    margin-bottom: 68px;
    align-items: center;
    background-color: #ffffff;
    
}

.uploadBtn button img{
    width:13.33px;
    height:13.33px;
}
.studHeader{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}
.studInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 40px;
    margin-right: 64px;
}

.studText h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
    font-family: inter;
}
.studText p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}

.studText{
    display:flex;
    justify-content: space-between;
}

.studText div{
    width:20%;
}

.studText2{
    display:flex;
    justify-content: space-between;
}

.studText2 div{
    width:20%;
}
.studText2 p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}

.studText2 h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}

.studText3{
    display:flex;
    justify-content: space-between;
}

.studText3 p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}
.studText3 h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}
.studText3 div{
    width:20%;
}

.addrHeader{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}
.addrText div{
    width:20%;
    
}

.addrInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 30px;
    padding-right: 40px;
    margin-right: 64px;
}

.addrText{
    display:flex;
    justify-content: space-between;
}

.addrText h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}

.addrText p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}


.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px;
    /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.maincontent {
    flex: 1;
    /*Take remaining width*/
    /* padding: 20px; */
    background-color: #f0f0f0;
    /* Other styles for the main content */
    /* padding-left: 40px; */
    height: fit-content;
    padding-bottom: 20px;

}

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.viewbtn{
  background-color: #2D995F;
  padding: 10px 40px 10px 40px;
  margin-top: 24px;
  border: 0;
  color: #ffffff;
}
.bckimg{
  width: 15px;
  height: 15px;
  padding-top: 2.2px;
}

.sBack{
  display: flex;
}
.studtxt{
  padding-top: 0px;
}

.dshbrdtxt {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid #CED4DA;
  padding-left: 25px;
  padding-bottom: 7px;
  margin-top: 20px;
}

.appcontainer {
  display: flex;
  height: 100vh;
}

.dreg {
  width: 100%;
}

.sidenav {
  flex: 0 0 300px;
  /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto;
  /* Enable vertical scrolling */
}

.maincontent {
  flex: 1;
  /*Take remaining width*/
  /* padding: 20px; */
  background-color: #f0f0f0;
  /* Other styles for the main content */
  /* padding-left: 40px; */
  height: fit-content;
  padding-bottom: 20px;

}

.scrollable {
  padding: 30px;
  /* Additional styles for the scrollable content inside the sidebar */
}

.icons {
  display: flex;
  gap: 15px;
}

.subContainer img{
  width: 5px;
  height:11px;
}
.subContainer{
  display:flex;
  align-items: center;
  gap:10px;
  margin: 0;
  margin-left: 10px;
}
.subContainer p{
  color: #343A40;
  font-weight: 500;
  font-size: 14px;
}

.heas {
  color: #343A40;
  font-size: 20px;
  font-weight: 400;
  /* padding-bottom: 20px; */
}

.underSelection {
  display: flex;
  width: 95%;
  justify-content: space-between;
  /* gap: 70px; */
  /* border: 1px solid pink; */
  align-items: center; /* Center items vertically if needed */ 
  
}



.date{
  padding: 0;
  width: auto;
}
.courses {
  width: 255px;
    padding: 10px;
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    margin-bottom: 4%;
    font-weight: 400;
    color: #6C757D;
  /* margin-left: 50px; */
}
.courses101{
  width: 255px;
    padding: 10px;
    border: 1px solid #ADB5BD;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    margin-bottom: 4%;
    font-weight: 400;
    color: #6C757D;
}
 
.labelFr {
  position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: #343A40;
    padding: 0 5px;
    font-size: 11px;
}
.labelFrs {
  position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: #343A40;
    padding: 0 5px;
    font-size: 11px;
}
.formGroup{
  position: relative;
}


.createClass{
  padding: 1% 3% 1% 3%;
  width: 270px;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
  background-color: #2D995F;
  border-radius: 4px;
  border: 0;
  font-size: 16px;
  text-align: center;
  /* margin-left: 30px; */
  margin-top: 5%;
  /* line-height: 10px; */
  font-weight: 500;
  height: auto;
  margin-bottom: 0;
}

.inputField{
  /* width: 40%; */
  /* border: 1px solid green; */
  text-align: center;
  /* margin-top: 1%; */
  padding: 2% 0% 0% 4%;
}
.inputField h4{
  font-size: 20px;
  color: #343A40;
  font-weight: 700;
  /* margin-top: 10px; */
  margin: 0;
  margin-bottom: 10px;

}
.inputField input{
  outline: none;
  border-color: initial!important;
  border: 1px solid #ADB5BD!important;
  color: #6C757D!important;
}
.inputField .logo{
  width: 225px;
  height:  160px;
}
.inputField h2{
  color: #6C757D;
  font-size: 28px;
  margin: 0;
  font-weight: 700;
  margin-top: 3%;
  margin-bottom: 3%;
}

/* .topContnt{
  width: 100%;
  padding: 0% 0% 2% 2.2%;
  margin: 0;
} */

/* .selectses .courses{
  margin-left: 0
}
.topFr{
  margin-right: 0;
 }   */

 .topDiv{
  display:flex;
  align-items: center;
  gap: 10px;
  margin-left: 43px;
  /* font-size: 30px;
  font-weight: 500; */
  }
  
  .topDiv img{
  width:5px;
  height:11px
  }
  
  .topDiv p{
  font-size: 16px;
  font-weight: 500;
  color:#343A40;
  }
  
  .container {
  width: 95%;
  margin: auto;
  background-color: white;
  /* padding-top: 20px; */
  margin-left: 0;
  }
  
  /* .container h2 {
  margin-bottom: 10px;
  } */
  
  /* .container p {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #2D995F;
  } */
  
  .container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  }
  
  .tScore .sScore{
  background-color: #ffffff;
  color: #343A40;
  font-weight: 700;
  border: 1px;
  }
  
  .thScore .sScore {
  padding: 15px;
  text-align: left; 
  font-size: 14px;
  font-weight: 700;
  /* background-color: #ffffff; */
  /* align-items: start; */
  border:1px solid #ADB5BD;
  
  }
  .bScore{
    border: 1px solid #ADB5BD;
    font-size: 13px;
    padding: 15px;
    background-color:#ffffff;
  }
  .bScore1{
    border: 1px solid #ADB5BD;
    font-size: 13px;
    padding: 15px;
    padding-left: 20px;
    background-color:#ffffff;
    width:70%;
    text-align: left;
  }
  
  
  
  /* .tbScore .bScore{
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  font-weight: 400;
  color:#6C757D;
  background-color: #ffffff;
  } */
  
  /* input[type="text"] {
  width: 50px;
  text-align: center;
  border:0.5px solid #2D995F;
  border-radius: 4px;
  padding:6px 12px 6px 12px;
  font-size: 14px;
  font-weight: 400;
  color:#2D995F;
  
  } */
  
  .container .uploadbtn {
  width:auto;
  background-color: #2D995F;
  color: white;
  padding: 10px 60px 10px 60px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 0;
  height: 38px;
  }
  
  .uploadbtn:hover {
  background-color: #45a049;
  }
  
  .dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    /* margin-top: 44px; */
    /* margin-left:43px; */
    margin-bottom:11px
  }
  
  .appcontainer {
    display: flex;
    height: 100vh;
  }
  
  
  
  .sidenav {
    flex: 0 0 300px;
    /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto;
    /* Enable vertical scrolling */
  }
  
  .maincontent {
    flex: 1;
    /*Take remaining width*/
    /* padding: 20px; */
    background-color: #f0f0f0;
    /* Other styles for the main content */
    /* padding-left: 40px; */
    height: fit-content;
    padding-bottom: 20px;
  
  }
  
  .scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
  }
  
  :root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
  }
  
  body {
    background-image: none;
    font-family: inter;
  }
  
  .moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
  }
  
  .txt {
    font-size: 14px;
    color: #6C757D;
  }
  
  .dashBoardDiv {
    margin-top: 25px;
    padding-bottom: 12px;
    width: 90%;
    /* height: 90%; */
    /* background-color: pink; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
  }
  
  


 .clearD{
  padding: 12px 28px;
  background-color: #86591D;
  /* height: 32px; */
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  margin-right: 10px;
 }

 .viewstudbtn{
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
 }

 .hScore{
   height: 40px;
   align-items: center;
   text-align: left;
   /* padding: 20px; */
 }
 .scoreTable{
  border: 1px #ADB5BD;
  display: table;
  } 
 .cgprow{
  display: flex;
  justify-content: space-between;
 }
 .cgpr{
  font-weight: 700;
  font-size: 14px;
  color: #343A40;
 }
 .showingresult{
  color: #2D995F;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 30px;
 }
 .prntresbtn{
  color: #ffffff;
  background-color: #2D995F;
  border: 0;
  padding: 12px 110px;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 4px;

 }
 .thetopoftable{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0%;
  border: 1px solid #ADB5BD ;
  padding-left: 50px;
  padding-right: 40px;

 }
 .topoftable{
  font-weight: 500;
  font-size: 14px;
 }