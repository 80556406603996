@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #373440;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
    /* width: 1027px;
height: 637px;
    */
}

button {
    cursor: pointer;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}


.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    /* width: 100%; */
    height: auto !important;
    /* background-color: pink; */
    width: 91%;
    height: 90%;
    padding-left: 40px;
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
    padding-right: 2%;
}

.headerText h4 {
    font-size: 16px;
    font-weight: bold;
    color: #6C757D;
    margin-bottom: 20px;
    padding-bottom: 12px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.topDivs {
    display: flex;
    gap: 1%;
    width: 100%;
    margin-top: 48px;
    /* margin-right: 40px; */
    padding-left: 0%;
    margin-left: 3px;
}

.wholePage{
gap: 0px;
border-radius: 4px;
opacity: 0px;
background-color: #FFFFFF;
justify-content: center;
margin-left: -25px;
/* padding-right: 24px; */
padding-bottom: 23px;
}

.first {
    position: relative;
    display: inline-block;
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;



    /* background-attachment: fixed; */

}

.second {
    /* background: url('/src/Assets/second1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* background-attachment: fixed; */
    position: relative;
    display: inline-block;
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
}

.third {
    /* background: url('/src/Assets/third1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* background-attachment: fixed; */
    position: relative;
    display: inline-block;
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
} 
.bookimg{
    display: block;
    width: 287px;
    height: 160px;
    border-radius: 8px;
}
.thetxt{
    position: absolute;
    top: 0;
    left: 0;
}

.btncourse {
    margin-bottom: 23px;
    margin-left: 27px;
width: 87px;
height:27px;
/* padding: 8px 36px 8px 36px; */
gap: 10px;
border-radius: 4px;
opacity: 0px;
border: 0;

}
.vbutton{
    margin-bottom: 23px;
    margin-left: 27px;
width: 87px;
height:27px;
/* padding: 8px 36px 8px 36px; */
gap: 10px;
border-radius: 4px;
opacity: 0px;
border: 0;
margin-top: 35px;
}
.head{
    width: 120px;
    height: 50px;
    font-weight: 700;
    color: #ffffff;
    size: 28px;
    font-family: inter;
    margin-left: 27px;
    margin-top: 31px;
    margin-bottom: 4px;

}

.heads {
    width: 120px;
    height: 50px;
    font-weight: 700;
    color: #ffffff;
    size: 28px;
    font-family: inter;
    margin-left: 27px;
    margin-top: 17px;
    margin-bottom: 18px;
}

.paragraph {
    width: 179px;
    height: 11px;
    top: 115px;
    left: 27px;
    gap: 0px;
    opacity: 0px;
    font-size: 8px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #ffffff;
    margin-left: 27px;
    margin-bottom: 20px;

}

.courses111 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 1px;
    margin-left: 0;
    padding-left: 0;
    width: 93%;
}

/* .courses2 {
    display: flex;
    gap: 1px;
    margin-left: 0px;
    padding-left: 0%;
} */

.topics {
    display: block;
border: 1px solid #2D995F;
margin-top: 8px;
margin-left: 10px;
border-radius: 4px;
width: auto; 
height: 141px; 

}

.separator {
    height: 3px;
}

.french {
    /* width: 269px;
height: 72px; */
margin-bottom: 0px;
margin-top: 0px;
margin-left: 12px;
gap: 0px;
opacity: 0px;
font-weight: 300;
font-size: 6px;
width: 189px;
height: 54px;
}
h4{
height: 14px;
margin-left: 13px;
gap: 0px;
opacity: 0px;
font-weight: 500;
font-size: 15px;
margin-top: 15px;
}

.coursetopic {
    width: 96px;
    justify-content: center;
    margin-top: 10px;
    margin-left: 12px;
    padding: 8px 16px 8px 16px;
    gap: 10px;
    border-radius: 4px;
    opacity: 0px;
    background-color: #86591D33;
    font-weight: 400;
    font-size: 10px;
    color: #86591D;

}
.kilobyte{
margin-top: 7px;
margin-left: 12px;
gap: 0px;
opacity: 0px;
font-weight: 400;
font-size: 10px;
color: #343A40;

}

.date {
    width: 177px;
    height: 12px;
    font-weight: 300;
    font-size: 7px;
    color: #6C757D;
    margin-left: 12px;
    margin-top:0px;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
}
.text{
    color: #2D995F;
    cursor: pointer;
}

.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}