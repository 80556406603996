.navbrd {
    display: flex;
    width: 100%;
    /* position: fixed; */
    background-color: #ffffff;
}

.horinfodashbrd {
display: flex;
justify-content: space-between;
width: 100%;
height: 90px;
border: 1px solid #CED4DA;
}

.usrcnr {
    display: flex;
    align-items: center;
    gap: 10%;
    width: 30%;
    height: 90px;
}

.logodiv {
    height: 75px;
}

.logo {
    width: 215px;
    height: 54px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 10px 15px 5px 35px;
}

.avtr {
    width: 54px;
    height: 54px;
}

.bel {
    width: 24px;
    height: 24px;
}

.usrinfo {
    display: flex;
}

.vrtlne {
    height: 54px;
    background-color: #ADB5BD;
    width: 2px;
}

.navdshbrd {
    border: 1px solid #CED4DA ;
    width: 100%;
}

.stinfo {
    padding-left: 20px;
}
.stinfosts {
    font-weight: 400;
    font-size: 12px;
    font-family: 'inter';
    margin-bottom: -17px;
}
.stinfolvl {
    font-weight: 700;
    font-size: 20px;
    font-family: 'inter';
}

.usrnme {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: -8px;
}

.usrpstn {
    font-weight: 300;
    font-size: 10px;
}

.gnrltxt {
    color: #ADB5BD;
    font-weight: 700;
    font-size: 12px;
}

.dshbrdtxt {
    font-weight: 400;
    color: #6C757D;
    font-size: 14px;
    
}

.icondshbrd {
    width: 16px;
    height: 16px;
}

.gnrlcls {
    border: 1px solid #ADB5BD;
    padding: 28px  0  28px 35px;
}
.gnrlclssub {
    /* border: 1px solid #ADB5BD; */
    padding: 5px  0  0px 10px;
}

/* .sidebaritem.active {
    background-color: #d3d3d3;
    font-weight: bold;
  } */
/* .sidebaritem {
    background-color: #d3d3d3;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  } */

  .navdshbrd .active {
    background: #2D995F;
    border-radius: 4px; 
    padding: 0px 5px 0px 10px;
}
  .navdshbrd .active  .dshbrdtxt{
    color: white; 
    text-decoration: none;
}
  .navdshbrd .active  a {
    text-decoration: none;
}

.active-icon {
    width: 16px;
    height: 16px;
    margin-left: auto; /* Pushes the active icon to the right */
}

.sidebaritem {
    text-decoration: none;
    color: inherit; /* Ensure it inherits the parent color or set your desired color */
}

.sidebaritem:hover {
    text-decoration: none; /* Ensure no underline on hover as well */
}

.thetxt {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5%;
    margin-top: -7px;
}
.thetxtlgot {
    margin-top: -7px;
    border: 1px solid #ADB5BD;
    padding: 1px 10px 1px 10px;
    border-radius: 4px;
    width: 80%;
}

.lgtupd {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}
.logoutDis{
    text-decoration: none;
}