@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-top: 100px;
}

.txt {
    font-size: 18px;
    font-weight: 700;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 5%;
    padding-bottom: 7%;
    width: 95%;
    height: auto;
    background-color: var(--White);
    justify-content: center;
    text-align: center;
    margin-left: 2.1%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* background-color: pink; */
}
.innerDiv {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.girlImg {
    width: 210px;
    height: 233px;
    /* margin: 0; */
}

.dashBoardBtn {
    background-color: #2D995F;
    border: none;
    border-radius: 4px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 60px;
    padding-right: 60px;
    cursor: pointer;
    color: #ffffff;
    width:auto;
    display:flex;
    align-items: center;
    justify-content: center;
}

.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.pysf {
    width: 296.3px;
    height: 152.78px;
    margin-bottom: 18px;
}

img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease
}

img:hover {
    transform: scale(1.1);
    /* Scale the image to 110% on hover */
}

.rgstrurcurses {
    width: 296.3px;
    height: 152.78px;
}

.hostlfees {
    width: 140.74px;
    height: 219.44px;
}

.fundurwlt {
    width: 140.74px;
    height: 219.44px;
}

.chekurrslt {
    width: 140.74px;
    height: 219.44px;
}

.gturcrsmtrls {
    width: 140.74px;
    height: 219.44px;
}

.qlnks {
    display: flex;
    /* justify-content: space-around; */
    align-items: left;
    margin-left: 25px;
    gap: 18px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 10px;
    margin-top: 20px;
}

.frstcolumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: auto;
    overflow: hidden;
}

.frstsubcolumn {
    display: flex;
    justify-content: space-between;
}

.qlnktxt {
    font-size: 12px;
    font-weight: 400;
    color: #6C757D;
    padding-left: 25px;
    margin-top: -15px;
}