@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    width: 88%;
    height: auto;
    /* background-color: pink; */
    padding-left: 40px;
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

.myProfile{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-bottom: 20px;
    margin-left: 0;
    /* padding-bottom: 12px; */
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.avt {
    width: 132px;
    height: 132px;
}

.usrinfo {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    border:1px solid #CED4DA;
    border-radius: 4px;
    margin-right: 66px;
}

.userinfoSub{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    padding:24px 0px 24px 25px;
}
.usrnminf {
margin-left: 15px;
}

.usrnme {
    font-weight: bold;
    font-size: 18px;
    margin-bottom:8px;
    /* margin-top: 41px; */
}

.usrpstn {
    font-weight: 500;
    font-size: 16px;
    color:#6C757D;
    margin-top: 0;
}
.uploadBtn{
    position:relative;
    
   
}
.uploadBtn button{
    display:flex;
    padding:14px 22px;
    gap: 10px;
    width:160px;
    height:45px;
    border:1px solid #2D995F;
    border-radius: 4px;
    color:#2D995F;
    margin-top: 67px;
    margin-right: 34px;
    margin-bottom: 68px;
    align-items: center;
    background-color: #ffffff;
    
}

.uploadFile{
    position:absolute;
    top:35%;
    left: 12%;
    width:70%;
    height:22%;
    cursor: pointer;
    opacity: 0;
}

.uploadBtn button img{
    width:13.33px;
    height:13.33px;
}
.studHeader{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}
.studInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 40px;
    margin-right: 64px;
}

.studentInfo{
    font-size: 16px;
    font-weight: 700;
    color:#6C757D;
    margin-top: 0px;
    font-family: inter;
    margin-right: 0;
    margin-left:0;
    width:auto;
}
.studText .textInfo{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}

.studText .textName{
    font-size: 14px;
    font-weight: 500;
    color:#343A40;
    margin-left: 0;
    margin-bottom: 12px;
    width:auto;
}

.studText{
    display:flex;
    gap:50px;
    justify-content: center;
    align-items: center;
}

.studText .textInfo{
    width:20%;
}

.studText2{
    display:flex;
    gap:50px;
    justify-content: center;
    align-items: center;
}

.studText2 .textInfo{
    width:20%;
}

.studText2 .textInfo{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}
.studText2 .textName{
    font-size: 14px;
    font-weight: 500;
    color:#343A40;
    margin-top: 0px;
    margin-left: 0;
    width:auto;
}

.studText3{
    display:flex;
    justify-content: space-between;
}

.studText3 p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}
.studText3 h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}
.studText3 div{
    width:20%;
}

.headerText{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
    margin-left: 0;
    width:auto;
}
.addrText .infoAddres{
    width:20%;
    
}

.addrInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 30px;
    padding-right: 40px;
    margin-right: 64px;
}

.addrText{
    display:flex;
    gap:50px;
}

.infoValue{
    font-size: 14px;
    font-weight: 500;
    color:#343A40;
    margin-top: 0px;
    margin-left: 0;
    width:auto;
    margin-bottom: 30px;
}

.infoText{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}


.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
