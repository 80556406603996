@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding: 2.5% 2% 2% 2%;
    width: 88%;
    height: auto;
    /* background-color: pink; */
    /* padding-left: 40px; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%; 
    align-items: center; 
}

.headerText h4{
    font-size: 16px;
    font-weight:600;
    color:#6C757D;
    margin-bottom: 0px;
    padding-bottom: 12px;
}

.headerText p{
    font-size: 12px;
    color:#6C757D;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom:0px;
    padding-bottom: 12px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 7px;
    margin-top: 20px;
}



.appcontainer {
    display: flex;
    height: 100vh;
}
.dreg{
    width: 100%;
}
.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }
.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

/* module.module.css */
.tableContainer {
    /* margin: 20px; */
    width: 100%;
    border: none;
}

.transHtry {
    border-collapse: collapse;
    width: 100%;
    color: #6C757D;
    font-size: 14px;
    font-weight: 400;
    border: none;
}

.transHtry th {
    background-color: #2D995F;
    padding: 1.5% 0% 1% 3%;
    text-align: left;
    /* border-bottom: 1px solid #ddd; */
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
}

.transHtry td {
    /* padding: 8px; */
    padding: 1% 0% 1% 3% ;
    text-align: left;
}

.transHtry tbody tr:nth-child(even) {
    background-color: #E9ECEF;
}

.artop {
    display: flex;
    /* align-items: left; */
    /* justify-content: space-between; */
    /* gap: 15px; */
    font-size: 16px;
    font-weight: 500;
}

.link11 {
    text-decoration: none;
    color: #343A40;
}

.arleft {
    margin: 19px;
}
.toptable {
    display: flex;
    align-items: left;
    justify-content: space-between;
    margin-left: 15px;
    /* gap: 15px; */
}

.lefttable {
    color: #343A40;
    font-size: 18px;
    font-weight: 700;
}

.lefttable1 {
    /* background-color: #6C757D; */
    font-size: 18px;
    font-weight: 700;
    display: flex;
    width: auto;
    margin-left: 10px;
    /* align-content: center; */
    justify-content: center;
    p {
        margin: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #6C757D;
        margin-left: 15px;
    }
}

.lefttable12 {
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    /* display: flex; */
    width: 129px;
    height: 129px;
    margin-top: 20px;
    /* align-content: center; */
    justify-content: center;
    p {
        margin: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #6C757D;
        margin-left: 15px;
    }
}

.righttable {
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: #86591D;
    /* padding: 10px, 100px; */
    margin: 0;
    border-radius: 4px;
    justify-content: center;
    text-align: center;
    height: 39px;
    /* color: #6C757D; */
    font-size: 12px;
    font-weight: 400;
    width: 203px;
}

.transHtry th,
.transHtry td {
    /* padding: 10px; Adjust padding as needed */
    /* text-align: left; */
    white-space: nowrap; /* Prevent text wrapping */
    /* overflow: hidden; Hide overflow text */
    text-overflow: ellipsis;
}
.tableContainer {
    margin: 20px;
}

.transHtry {
    border-collapse: collapse;
    width: 95%;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    table-layout: fixed; /* Fixed table layout to prevent overflowing */
}

.flow {
    display: flex;
    align-items: center; /* Align items vertically in the flex container */
}

.flow img {
    width: 32px;
    height: 32px;
}

.transHtry tbody .thRow:nth-child(even) {
    background-color: #E9ECEF;
}

.transHtry .tbData:nth-child(3),
.transHtry th:nth-child(3) {
    text-align: center;
}
.transHtry .tbData:nth-child(2),
.transHtry th:nth-child(2) {
    text-align: center;
    padding-left: 20%;
}
.transHtry .tbData:nth-child(3),
.transHtry th:nth-child(3) {
    text-align: center;
    padding-left: 5%;
}
.transHtry .tbData:nth-child(4),
.transHtry th:nth-child(4) {
    text-align: center;
    /* padding-left: 5%; */
}
.printReButton{
    cursor: pointer;
    border: none;
    color: #2D995F;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    font-family: inter;
    border: 0.3px solid #2D995F;
    background-color: transparent;}

.printReButton2{
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: #2D995F;
    font-size: 12px;
    font-weight: 400;
    margin-left: 15px;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    font-family: inter;
    width: 90px;
}

.printReButton22{
    cursor: pointer;
    border: none;
    color: #ffffff;
    background-color: #2D995F;
    font-size: 12px;
    font-weight: 400;
    margin-left: 15px;
    padding: 5px 15px 5px 15px;
    border-radius: 4px;
    width: 287px;
    height: 43px;
}

.newdiv {
    margin-left: 15px;

    span {
        font-size: 16px;
        font-weight: 400;
        /* margin-top: 200px; */
    }

    p {
        font-size: 10px;
        font-weight: 300;
        /* margin-top: 10px; */
    }

    h5 {
        font-size: 14px;
        font-weight: 400;
        color: #343A40;
        margin: 0;
    }
}
table {
    border-collapse: collapse;
    width: 95%;
    border-collapse: collapse;
    color: #6C757D;
    font-size: 14px;
    font-weight: 400;
    border: none;
    /* align-items: center; */
  }
  
  td {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 15px;
    justify-content: center;
    text-align: center;
  }

  th {
    /* border: 1px solid #dddddd; */
    font-size: 14;
    font-weight: 400;
    text-align: center;
    padding: 15px;
    color: white;
    background-color: #2D995F;
  }
  
  tr:nth-child(odd) {
    background-color: #E9ECEF;
  }

  .buttonsept {
    display: flex;
    padding: 10px;
  }

  .arrow {
    height: 16px;
    width: 16px;
  }