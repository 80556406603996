.navbrd {
    display: flex;
    width: 100%;
    background-color: #ffffff;
}

.horinfodashbrd {
display: flex;
justify-content: space-between;
width: 100%;
height: 75px;
border: 1px solid #CED4DA;
}

.usrcnr {
    display: flex;
    align-items: center;
    gap: 10%;
    width: 30%;
    height: 90px;
    
}



.avtr {
    width: 54px;
    height: 54px;
}

.bel {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.usrinfo {
    display: flex;
    margin-bottom: 10px;
    padding-left: 20px;
}

.usrnminf {
margin-left: 15px;
}

.vrtlne {
    height: 54px;
    background-color: #ADB5BD;
    width: 2px;
    margin-bottom: 10px;
}

.navdshbrd {
    border: 1px solid #CED4DA ;
    width: 30%;
}

.stinfo {
    /* padding-left: 20px; */
}
.stinfosts {
    font-weight: 400;
    font-size: 12px;
    font-family: 'inter';
    margin-bottom: -17px;
}
.stinfolvl {
    font-weight: 700;
    font-size: 20px;
    font-family: 'inter';
}

.usrnme {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: -8px;
}

.usrpstn {
    font-weight: 300;
    font-size: 10px;
}





