@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    width: 95%;
    height: auto;
    /* background-color: pink; */
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

  .subContainer{
    display:flex;
    align-items: center;
    text-align: center;
    gap:10px;
    margin-left: 22px;
    margin-bottom: 20px;
}

.subIcon1{
    width: 5px;
    height:11px;
}

.subText1{
    font-size: 14px;
    font-weight: 400;
    color:#6C757D;
}
.assignmentdetailscontainer {
  padding: 20px;
  padding-top: 0px;
  margin: auto;
  margin-left: 10px;
}

.assignmentheader {
  margin-bottom: 30px;
}

.assignmenttitle {
  font-size: 16px;
  font-weight:700;
  color: #2D995F;
  margin-bottom: 8px;
  margin-top: 0;
  margin-left: 0;
}

.assignmentsummary {
  font-size: 12px;
  font-weight: 400;
  margin-top: 0;
  color: #6c757d;
  margin-bottom: 20px;
}

.assignmentdates {
  display: flex;
  gap:37px;
}

.uploaddate, .submitdate {
  display:flex;
}

.uploaddate1{
  background-color: #2D995F;
  padding:8px 20px;
  color:#ffffff;
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px 0 0 8px;
  align-items: center;
  text-align: center;
}

.uploaddate2{
  background-color: #ffffff;
  color:#2D995F;
  font-size: 12px;
  font-weight: 400;
  border:1px solid #2D995F;
  border-radius: 0px 8px 8px 0px;
  padding:8px 20px;
}

.submitdate1{
  background-color: #86591D;
  padding:8px 20px;
  color:#ffffff;
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px 0 0 8px;
  align-items: center;
  text-align: center;
}

.submitdate2{
  background-color: #ffffff;
  color:#86591D;
  font-size: 12px;
  font-weight: 400;
  border:1px solid #86591D;
  border-radius: 0px 8px 8px 0px;
  padding:8px 20px;
}

.assignmentdetails {
  line-height: 1.6;
  margin-bottom: 30px;
}

.detailsTop1{
  color:#343A40;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 0;
}

.detailsP1{
  color:#6C757D;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
}

.submitassignmentbutton {
  background-color: #2D995F;
  color: #ffffff;
  border: none;
  padding: 20px 69px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-left: 0px;
  width:auto;
  display:flex;
  align-items: center;
  justify-content: center;
}

.attchText1{
  font-size: 12px;
  font-weight: 400;
  color:#343A40;
  margin-left: 0;
}

.subAttach{
  display:flex;
  gap:20px;
  margin-bottom: 30px;
}

.subAttach div{
  /* border:1px solid #CED4DA;
  border-radius: 4px;
  padding:26px 34px; */
  
}
.Attach{
  width:auto;
}

.attachImg1{
  width: 60px;
  height:78px;
  align-items: center;
  margin: auto;
  border:1px solid #CED4DA;
  border-radius: 4px;
  padding:26px 34px;
  margin-left: 0;
}

.attachText2{
  color:#6C757D;
  font-size: 10px;
  font-weight: 300;
  width:80%;

}

.bbn{
  display: flex;
  gap:30px;
  align-items: center;
}

.bbnText{
  font-size: 16px;
  font-weight: 500;
  color:#FF1708;
  margin-top: 0;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 44px;
    margin-left:43px;
    margin-bottom:11px
}

.appcontainer {
    display: flex;
    height: 100vh;
}


.sidenav {
  flex: 0 0 300px; /* Fixed width for the sidebar */
  background-color: #f0f0f0;
  overflow-y: auto; /* Enable vertical scrolling */
  position: fixed; /* Fix the sidebar in place */
  height: 100vh; /* Full height of the viewport */
}

.maincontent {
  margin-left: 300px; /* Same width as the sidebar to prevent overlap */
  flex: 1;
  background-color: #f0f0f0;
  overflow-y: auto; /* Ensure main content can scroll if needed */
  height: 100vh; /* Full height of the viewport */
}

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
