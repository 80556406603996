@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-top: 100px;
}

.txt {
    font-size: 18px;
    font-weight: 700;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 1.4%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    width: 90%;
    background-color: var(--White);
    margin-left: 2.1%;
}

.innerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-toggle {
    display: flex;
    gap: 10px;
}

.toggle-button {
    padding: 10px 20px;
    background-color: grey;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.toggle-button.selected {
    background-color: green;
    color: white;
}

.toggle-button.selected::after {
    content: '';
    display: block;
    background-color: white;
    height: 2px;
    margin-top: 5px;
}





.dashBoardBtn {
    margin-left: 0px;
    background-color: #2D995F;
    border: none;
    border-radius: 4px;
    text-align: center;
    padding: 12px 90px 12px 90px;
    /* padding-bottom: 12px;
    padding-left: 60px;
    padding-right: 60px; */
    color: #ffffff;
}

.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}

.pysf {
    width: 296.3px;
    height: 152.78px;
    margin-bottom: 18px;
}

img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease
}

img:hover {
    transform: scale(1.1);
    /* Scale the image to 110% on hover */
}

.rgstrurcurses {
    width: 296.3px;
    height: 152.78px;
}

.hostlfees {
    width: 140.74px;
    height: 219.44px;
}

.fundurwlt {
    width: 140.74px;
    height: 219.44px;
}

.chekurrslt {
    width: 140.74px;
    height: 219.44px;
}

.gturcrsmtrls {
    width: 140.74px;
    height: 219.44px;
}

.qlnks {
    display: flex;
    /* justify-content: space-around; */
    align-items: left;
    margin-left: 25px;
    gap: 18px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 10px;
    margin-top: 20px;
}

.frstcolumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: auto;
    overflow: hidden;
}
.lvTable {
    margin-top: 2%;
    width: 100%;

}
.lvTable {
    margin-top: 2%;
    border: 1px solid #ced4da;
    border-radius: 8px;
    width: 100%;
}
.lvTable2{
    margin-top: 2%;
    width: 100%;
    background-color: #E9ECEF;
    border-radius: 8px;
    /* border: none!important; */
}
.timeIconContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}
.month{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    align-items: center;
}
.month img{
    width: 27px;
    height: 27px;
    margin: 0 5px;
}
.theHeaderTxt{
    gap: 30px;
    display: flex;
}
.header{
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.createClass{
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: #2D995F;
    font-weight: 400;
}
.createClass img{
    width: 24px;
    height: 24px;
    margin-top: -4px;
}
.lectureTxt{
    color: #343A40;
    font-size: 16px;
    font-weight: 700;
}
.lvTableMain {
    width: 100%;
    padding: 1% 0% 1% 0%;
    display: flex;
    gap: 30px;
    text-align: center;
    position: relative;
}
.lvTableMain span{
    text-align: center;
    margin-left: 10px;
}
.sldk{
    margin-top: -20px;
    display: flex;
    flex-direction: column;
}

.dateRow {
    font-size: 18px;
    color: #2d995f;
    /* margin: 0; */
    font-weight: 700;
    margin-top: 0px;
}
.verLine{
    width: 1px;
    background-color:#CED4DA;
}
.dateRow span{
    font-size: 14px;
    color: #2d995f;
    font-weight: 500;
}

.iconTxt {
    display: flex;
    align-items: center; /* Vertically center items in the flex container */
    gap: 10px; /* Adjust as needed */
}

.iconTxt img {
    width: 22px;
    height: 22px;
}

.iconTxt p {
    margin: 0; /* Remove default margin */
}
.courseInfo{
    margin-left: 8%;
    color: #6C757D;
    font-size: 14px;
}
.hfh4{
    color: #343A40;
    font-size: 34px;
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}
.locationIcon{
    width: 28px;
    height: 28px;
}
.linkBtn{
    justify-self: end;
    position: absolute;
    right: 0;
    top: 35%;
    margin-right: 4%;
    white-space: nowrap;
}
.linkBtn button{
    font-size: 12px;
    color: #ffffff;
    background-color: #2D995F;
    border-radius: 4px;
    padding: 5px 17px 5px 17px!important;
    height: auto;
    width: auto;
}
