@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}
.createClassPo{
    position: absolute;
    right: 0;
    margin-right: 2%;
    top: 3.4%;
}
.header{
    display: flex;
    width: 100%;

}
.moving {
    padding-left: 400px;
    padding-top: 100px;
}
.month{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #6C757D;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 50px;
    margin-top: -1.4%;
}
.month img{
    width: 27px;
    height: 27px;
    margin: 0 5px;
}
.createClass{
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: #2D995F;
    font-weight: 400;
}
.createClass img{
    width: 24px;
    height: 24px;
    margin-top: -4px;
}

.crteassgnmnt {
    width: 284.91px;
    height: 147.96x;
}

.allcrtendview {
    display: flex;
    margin: 20px 10px 20px 0px;
    gap: 1.5%;
}

.dgst {
    margin-top: 40px;
}

.frstbx {
    border: 1px solid #2D995F;
    border-radius: 4px;
    padding: 8px 15px 10px 20px;
    width: 45%;
    
}

.entbx {
    display: flex;
    gap: 2%;
}
.entbx1 {
    display: flex;
    gap: 2%;
    margin-top: 20px;
}

.crsetpcs {
    font-size: 16px;
    font-weight: 500;
    color: #343A40;
}

.crsetpcsbx {
    font-weight: 400;
    font-size: 12px;
    color: #86591D;
    text-align: center;
    background-color: rgba(134, 89, 29, 0.2);
    padding: 10px 5px;
    width: 135px;
    /* width: 15%; */
    border-radius: 4px;
    font-family: 'inter';
}

.kbts {
    font-size: 16px;
    font-weight: 400;
    color: #343A40;
    font-family: 'inter';
    /* width: 35%; */
    width: 100%;
}

.kbtslorem {
    font-size: 10px;
    font-weight: 300;
    /* width: 33%; */
    width: 100%;
    margin-top: -10px;
    color: #6C757D;
}

.dtedtls {
    display: flex;
    width: 100%;
    /* width: 32%; */
    justify-content: space-between;
}

.crteddte {
    font-size: 10px;
    font-family: 'inter';
    font-weight: 300;
}

.crteddtevw {
    font-size: 10px;
    font-family: 'inter';
    font-weight: 300;
    color: #2D995F;
}

.txt {
    font-size: 18px;
    font-weight: 700;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 1.4%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 3%;
    width: 90%;
    position: relative;
    background-color: var(--White);
    margin-left: 2.1%;
}




.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }

.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}



img:hover {
    transform: scale(1);
    /* Scale the image to 110% on hover */
}



.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 10px;
    margin-top: 20px;
}
