@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&family=Open+Sans:wght@700&family=Roboto:wght@300;500&display=swap');

/* colors */
:root {
    --Deep-green: #164B2E;
    --Light-green: #2D995F;
    --Gray: #f2f3f3;
    --White: #ffffff;
    --Black: #343A40;
    --blackGray: var(--Light-green);
    border: var(--Light-green);
}

body {
    background-image: none;
    font-family: inter;
}

.moving {
    padding-left: 400px;
    padding-right: 20px;
    padding-top: 100px;
}

.txt {
    font-size: 14px;
    color: #6C757D;
}

.dashBoardDiv {
    padding-top: 0.5%;
    padding-bottom: 12px;
    width: 90%;
    height: auto;
    /* background-color: pink; */
    padding-left: 18px;
    padding-right: 20px;
    justify-content: center;
    background-color: var(--White);
    margin-left: 3%;
}

.headerText h4{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-bottom: 20px;
    padding-bottom: 12px;
}

.dshbrdtxt {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 1px solid #CED4DA;
    padding-left: 25px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.avt {
    width: 132px;
    height: 132px;
}

.usrinfo {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    border:1px solid #CED4DA;
    border-radius: 4px;
    margin-right: 66px;
}

.userinfoSub{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    padding:24px 0px 24px 25px;
}
.usrnminf {
margin-left: 15px;
}

.usrnme {
    font-weight: bold;
    font-size: 18px;
    margin-bottom:8px;
    /* margin-top: 41px; */
}

.usrpstn {
    font-weight: 500;
    font-size: 16px;
    color:#6C757D;
    margin-top: 0;
}

.uploadBtn button{
    display:flex;
    padding:14px 22px;
    gap: 10px;
    width:160px;
    height:45px;
    border:1px solid #2D995F;
    border-radius: 4px;
    color:#2D995F;
    margin-top: 67px;
    margin-right: 34px;
    margin-bottom: 68px;
    align-items: center;
    background-color: #ffffff;
    
}

.uploadBtn button img{
    width:13.33px;
    height:13.33px;
}
.studHeader{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}
.studInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 40px;
    margin-right: 64px;
}

.studText h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
    font-family: inter;
    margin-right: 10px;
}
.studText p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}

.studText{
    display:flex;
    gap:50px;
}

.studText div{
    width:20%;
}

.studText2{
    display:flex;
    justify-content: space-between;
}

.studText2 div{
    width:20%;
}
.studText2 p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}

.studText2 h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}

.studText3{
    display:flex;
    justify-content: space-between;
}

.studText3 p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}
.studText3 h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}
.studText3 div{
    width:20%;
}

.addrHeader{
    font-size: 16px;
    font-weight:bold;
    color:#6C757D;
    margin-top: 44px;
    margin-bottom: 20px;
    padding-bottom: 0px;
}
.addrText div{
    width:20%;
    
}

.addrInfo{
    border:1px solid #CED4DA;
    border-radius: 4px;
    padding-left: 41px;
    padding-top: 36px;
    padding-bottom: 30px;
    padding-right: 40px;
    margin-right: 64px;
}

.addrText{
    display:flex;
    justify-content: space-between;
}

.addrText h4{
    font-size: 16px;
    color:#343A40;
    margin-top: 0px;
}

.addrText p{
    font-size: 12px;
    color:#6C757D;
    margin-bottom: 4px;
}


.appcontainer {
    display: flex;
    height: 100vh;
}



.sidenav {
    flex: 0 0 300px; /* Fixed width for the sidebar */
    background-color: #f0f0f0;
    overflow-y: auto; /* Enable vertical scrolling */
    position: fixed; /* Fix the sidebar in place */
    height: 100vh; /* Full height of the viewport */
  }
  
  .maincontent {
    margin-left: 300px; /* Same width as the sidebar to prevent overlap */
    flex: 1;
    background-color: #f0f0f0;
    overflow-y: auto; /* Ensure main content can scroll if needed */
    height: 100vh; /* Full height of the viewport */
  }
.topDivs{
    display: flex;
    gap: 1%;
    width: 100%;
    margin-top: 48px;
    /* margin-right: 40px; */
    padding-left: 0%;
    /* margin-left: 13px; */

}
.wholePage{
    width: 100%;
    /* margin-top: 36px;
    margin-left: 33px; */
    gap: 0px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;  
    justify-content: center;
    background-color: #ffffff;

/* margin-top: 226px;
margin-left: 318px; */


}

.first {
    position: relative;
    display: inline-block;
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;



    /* background-attachment: fixed; */

}

.second {
    /* background: url('/src/Assets/second1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* background-attachment: fixed; */
    position: relative;
    display: inline-block;
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
}

.third {
    /* background: url('/src/Assets/third1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* background-attachment: fixed; */
    position: relative;
    display: inline-block;
    width: 287px;
    height: 160px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
} 
.bookimg{
    display: block;
    width: 287px;
    height: 160px;
    border-radius: 8px;
}
.thetxt{
    position: absolute;
    top: 0;
    left: 0;
}

.btncourse {
    margin-bottom: 23px;
    margin-left: 27px;
width: 87px;
height:27px;
/* padding: 8px 36px 8px 36px; */
gap: 10px;
border-radius: 4px;
opacity: 0px;
border: 0;

}

.vbutton{
    margin-bottom: 23px;
    margin-left: 27px;
width: 87px;
height:27px;
/* padding: 8px 36px 8px 36px; */
gap: 10px;
border-radius: 4px;
opacity: 0px;
border: 0;
margin-top: 35px;
}
.head{
    width: 120px;
    height: 50px;
    font-weight: 700;
    color: #ffffff;
    size: 28px;
    font-family: inter;
    margin-left: 27px;
    margin-top: 31px;
    margin-bottom: 4px;

}
.heads{
    width: 120px;
    height: 50px;
    font-weight: 700;
    color: #ffffff;
    size: 28px;
    font-family: inter;
    margin-left: 27px;
    margin-top: 17px;
    margin-bottom: 18px;
}
.paragraph{
    width: 179px;
    height: 11px;
    top: 115px;
    left: 27px;
    gap: 0px;
    opacity: 0px;
    font-size: 8px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #ffffff;
    margin-left: 27px;
    margin-bottom: 20px;

}

h4{
    /* margin-left: 14px; */
    font-weight: 500;
    font-size: 16px;
    margin-top: 51px;
    color:#343A40; 
    }

.icon{
    width: 193px;
height: 156px;

/* margin-top: 136px;
margin-left: 593px; */

}
.data{
    width: 167px;
height: 17px;
font-weight: 400;
font-size: 14px;
/* margin-left: 606px; */
color: #6C757D;

}
.lastone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    /* margin-left: 50%; */
    margin-top: 136px;
}
.scrollable {
    padding: 30px;
    /* Additional styles for the scrollable content inside the sidebar */
}
